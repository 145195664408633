import { Route } from '@angular/router';

import { ComingSoonComponent, NotFoundComponent, UserAuthGuard } from '@rp/core';
import { ProfileTarget, TargetApp } from '@rp/shared/models';

import { ShellComponent } from './shell/shell.component';

export const appRoutes: Route[] = [
  {
    path: 'storybook',
    loadComponent: () => import('@rp/storybook').then(m => m.StorybookComponent),
  },
  {
    path: '',
    component: ShellComponent,
    canActivate: [UserAuthGuard],
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'offers-wall',
      },
      {
        path: 'profile',
        loadComponent: () => import('@rp/pages/profile').then(m => m.ProfileComponent),
        data: {
          header: 'general.webmasterId',
          target: ProfileTarget.Webmaster,
          title: 'headerTitle.profile',
        },
      },
      {
        path: 'my-offers',
        loadComponent: () => import('@rp/pages/my-offers').then(m => m.MyOffersComponent),
        data: {
          title: 'headerTitle.myOffers',
          header: 'general.webmasterId',
          target: ProfileTarget.Webmaster,
        },
      },
      {
        path: 'offers-wall',
        loadComponent: () => import('@rp/pages/offers-wall').then(m => m.OffersWallComponent),
        data: {
          title: 'headerTitle.offersWall',
          header: 'general.webmasterId',
          target: ProfileTarget.Webmaster,
        },
      },
      {
        path: 'my-offers/:id',
        loadComponent: () => import('@rp/pages/my-offers').then(m => m.OfferInfoComponent),
      },
      {
        path: 'offers-wall/:id',
        loadComponent: () => import('@rp/pages/offers-wall').then(m => m.OfferWallInfoComponent),
      },
      {
        path: 'instruments',
        component: ComingSoonComponent,
      },
      {
        path: 'infrastructure',
        component: ComingSoonComponent,
      },
      {
        path: 'shop',
        component: ComingSoonComponent,
      },
      {
        path: 'faq',
        loadComponent: () => import('@rp/static-pages').then(m => m.FAQComponent),
      },
      {
        path: 'finance',
        loadComponent: () => import('@rp/pages/finance').then(m => m.FinanceComponent),
        data: {
          target: TargetApp.Webmaster,
          title: 'menu.finance',
        },
      },
      {
        path: 'finance/view',
        loadComponent: () => import('@rp/pages/finance').then(m => m.DetailsPageComponent),
        data: {
          target: TargetApp.Webmaster,
          title: 'menu.finance',
        },
      },
      {
        path: 'statistic',
        loadComponent: () => import('@rp/pages/statistic').then(m => m.StatisticComponent),
        data: {
          target: TargetApp.Webmaster,
          title: 'statistic.statisticMainReport',
        },
      },
      {
        path: 'statistic/view',
        loadComponent: () => import('@rp/pages/statistic').then(m => m.StatisticDetailsComponent),
        data: {
          target: TargetApp.Webmaster,
        },
      },
      {
        path: '**',
        component: NotFoundComponent,
      },
    ],
  },
];
