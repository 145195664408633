import { Component, inject, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';

import { IconService } from '@rp/shared/services';

@Component({
  selector: 'rp-root',
  standalone: true,
  templateUrl: './app.component.html',
  imports: [RouterOutlet],
  providers: [IconService],
})
export class AppComponent implements OnInit {
  private _iconService = inject(IconService);

  ngOnInit(): void {
    this._iconService.registerIcons();
  }
}
