import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';

import {
  HeaderComponent,
  MobileSubHeaderComponent,
  ShellBaseComponent,
  SidebarComponent,
} from '@rp/core';

@Component({
  selector: 'rp-shell',
  standalone: true,
  templateUrl: './shell.component.html',
  styleUrl: './shell.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [AsyncPipe, HeaderComponent, SidebarComponent, RouterOutlet, MobileSubHeaderComponent],
})
export class ShellComponent extends ShellBaseComponent {
  isSidebarCollapsed = false;
}
